.menu--secondary {
    position: relative;
    font-size: px-to-rem(28);
    flex-direction: column;
    gap: 1em;

    .menu__item {
        height: initial;
        padding: unset;
        position: unset;

        &:hover {
            .menu__link {
                opacity: 1;
            }

            .menu__item-counter {
                opacity: 1;
            }
        }
    }

    .submenu {
        background-color: unset;
    }

    &:hover {
        .menu__link {
            opacity: 0.5;
        }
    }
}

;@import "sass-embedded-legacy-load-done:107";