img {
    @extend .img-fluid;

    &.aligncenter {
        display: block;
        margin: 0 auto;
    }
    &.alignright {
        display: block;
        margin-left: auto;
    }
    &.alignleft {
        display: block;
        margin-right: auto;
    }    
}
.img--visual {
    position: relative;
    z-index: 1;
    aspect-ratio: 16/9;
    max-width: 100% !important;
    width: 100%;
    max-height: 75vh;
 }
 .img--corner {
     clip-path: polygon(0 0, 100% 0, 100% calc(100% - #{spacer(11)}), calc(100% - #{spacer(11)}) 100%, 0 100%);
    @include media-breakpoint-up(lg) {
        clip-path: polygon(0 0, 100% 0, 100% calc(100% - #{spacer(13)}), calc(100% - #{spacer(13)}) 100%, 0 100%);
    }
 }
 .image-container {
    position: relative;
    z-index: 1;
 }
;@import "sass-embedded-legacy-load-done:85";