.mega-menu-submenu {
    margin: 0;
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: spacer(8) 0;
    opacity: 0;
    pointer-events: none;
    padding: 0;

    &.is-active {
        z-index: 10;
        pointer-events: initial;
        opacity: 1;
    }

    &:hover {
        .menu__link {
            opacity: 0.5;
        }
    }

    .menu__item {
        &:hover {
            .menu__link {
                opacity: 1;
            }

            .menu__item-counter {
                opacity: 1;
            }
        }
    }
}

[data-parent-depth="2"] {
    .menu__item {
        @include media-breakpoint-up(lg) {
            font-size: px-to-rem(20);
        }
    }
}

.megamenu-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding-bottom: spacer(4);
    justify-content: flex-start;

    @include media-breakpoint-up(md) {
        &--custom-height {
            height: fit-content;
        }
    }

    @include media-breakpoint-up(lg) {
        padding-bottom: 180px;
    }

    &__inner {
        display: flex;
        width: 100%;
        justify-content: flex-start;
        position: relative;
        height: 100%;
        transition: height 400ms ease-out;
    }

    &--bg {
        justify-content: flex-start;

        &:after {
            content: "";
            width: 50vw;
            background-color: rgba($white, 0.08);
            min-height: 100%;
            display: block;
            position: absolute;
            top: 0;
            pointer-events: none;
        }
    }
}

;@import "sass-embedded-legacy-load-done:110";