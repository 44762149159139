.breadcrumbs {
    &--is-singular {
        position: absolute;
        top: 100px;
        left: spacer(7);
        .breadcrumbs__seperator {
            &:not(:first-child) {
                display: none;
            }
        }
        .breadcrumbs__link, .breadcrumb_last {
            display: none;
            &--reversed {
                display: block;
            }
        }
        @include media-breakpoint-up(lg) {
            top: calc(100px + #{spacer(7)});
            .breadcrumbs__seperator {
                &:not(:first-child) {
                    display: block;
                }
            }
            .breadcrumbs__link, .breadcrumb_last {
                display: block;
            }
        }
    }
}

.breadcrumbs__nav {
    display: flex;
    align-items: center;
    @extend .list;
    margin: 0;
    font-family: $subheadings-font-family;
    letter-spacing: 0.2cap;
    font-size: px-to-rem(14);
}

.breadcrumbs__link {
    text-decoration: none;
    color: rgba($quinary, .5);

    &--reversed {
        color: $quinary;
        padding-right: spacer(4);
        font-family: $font-family-base;
    }
}

.breadcrumbs__link--divider {
    @include media-breakpoint-up(lg) {
        border-right: 1px solid rgba($quinary, .5);
    }
}

.breadcrumb_last {
    color: $quinary;
}

.breadcrumbs__seperator {
    color: $quinary;
    margin: 0 spacer(2);
    display: flex;
}

;@import "sass-embedded-legacy-load-done:102";