.usp {
    display: flex;
    flex-direction: column;
    gap: spacer(3);
}

.ups__title {
    @extend %heading;
    color: $white;
    font-size: px-to-rem(20);
    font-weight: $font-weight-semibold;
    margin: 0;
}

.ups__text {
}

;@import "sass-embedded-legacy-load-done:118";