%label {
    width: 100%;
    display: block;
    margin-bottom: $form-label-margin-bottom;
    @include font-size($form-label-font-size);
    font-weight: $font-weight-base !important;
    color: $quinary;
}

label,
.gfield_label {
    @extend %label;
}

.label {
    background: rgba($quinary, 0.07);
    font-family: $subheadings-font-family;
    font-size: px-to-rem(12);
    color: $quinary;
    padding: spacer(2) spacer(3);
    border-radius: $border-radius-pill;
    letter-spacing: 0.75px;
}

.label-container {
    margin-bottom: spacer(2);
}

.form-container {
    padding: spacer(4);
    overflow: hidden;
    background-color: $white;

    @include media-breakpoint-up(md) {
        padding: spacer(13);
    }

    &--shadow {
        box-shadow: 0 30px 45px rgba($black, 0.05);
    }
}

;@import "sass-embedded-legacy-load-done:122";