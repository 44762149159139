.card--article {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    border: 0;
    padding-bottom: spacer(10);
    box-shadow: none;
    row-gap: spacer(4);
    column-gap: spacer(10);
    border-bottom: 1px solid rgba($quinary, .1);
    background: transparent;
    border-radius: 0;

    &:hover { 
        box-shadow: none;

    }
    .card__header, .card__body, .card__footer {
        padding: 0;
    }
    .card__header {
        width: 100%;

    }
    .card__body, .card__footer {
        flex: 1;
    }
    .card__title {
        font-size: $h6-font-size;
    }
    .card__content {
        color: rgba($quinary, .75);
    }
    @include media-breakpoint-up(lg) {
        flex-direction: row;
    }
    &-grid {
        flex-direction: column;
    }
}

;@import "sass-embedded-legacy-load-done:92";