.logo-grid {
    display: grid;
    align-items: center;
    justify-content: center;
    gap: 60px 40px;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;

    @include media-breakpoint-up(lg) {
        gap: 100px 40px;
        grid-template-rows: auto;
        grid-template-columns: repeat(auto-fit, minmax(265px, 1fr));
    }
}

.logo-grid__item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
}

;@import "sass-embedded-legacy-load-done:88";