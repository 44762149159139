.editor {
    p {
        margin-bottom: spacer(5);
        
        &:last-child {
            margin-bottom: unset;
        }
    }

    strong {
        font-weight: $font-weight-bold;
    }

    blockquote {
        &:before {
            content: "";
            display: block;
            background-image: url("../../img/icons/quote.svg");
            background-repeat: no-repeat;
            width: 23px;
            height: 16px;
            margin-bottom: spacer(4);
            font-weight: $font-weight-normal;
        }

        font-family: $headings-font-family;
        line-height: $headings-line-height;
        color: $quinary;
        font-size: px-to-rem(40);

        
            position: relative;
            display: inline; 
            -webkit-text-fill-color: rgba($quinary, .5);
            -webkit-background-clip: text;
            background-repeat: no-repeat;
            background-image: linear-gradient($quinary, $quinary);
            background-size: 0% 100%;
        

    }

    .divider {
        @extend %divider;
        position: absolute;
        transform: translateY(-#{spacer(4)});
    }
}

;@import "sass-embedded-legacy-load-done:86";