.card-employee {
    display: flex;
    flex-direction: column;
    gap: spacer(8);

    .card__image {
        aspect-ratio: 4/5;
        height: auto;
        width: 100%;
        object-fit: cover;
        max-width: unset;
    }
}

.card-employee__header {
    position: relative;
}

.card--tetris-left {
    position: absolute;
    bottom: 0;
    left: 0;
    margin: spacer(4);
}

.card--tetris-right {
    position: absolute;
    right: 0;
    top: 0;
    margin: spacer(4);
}

.card-employee__subtitle {
    @extend .subheading;
}

.card-employee__title {
    @extend .h4;
    font-size: px-to-rem(24);
}

.card-employee--vertical {
    display: flex;
    padding: spacer(5);
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: spacer(8) spacer(5);

    .card-employee__content {
        flex: 1;
    }

    @include media-breakpoint-up(lg) {
        padding: spacer(12);
        gap: spacer(5);
    }

    .card__image {
        height: auto;
        aspect-ratio: 1/1;
        object-fit: cover;
        max-width: 88px;
        object-position: top;
    }
}

;@import "sass-embedded-legacy-load-done:91";