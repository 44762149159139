// team swiper

.swiper--team {
    @include media-breakpoint-up(lg) {
        .swiper-wrapper {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: spacer(0) spacer(20);
            overflow: unset !important;
        }

        .swiper__slide {
            width: auto !important;
            margin-right: 0 !important;

            &:nth-child(3n+2) {
                margin-top: spacer(20);
            }

        }

        .card-employee--pull-down {
            margin-top: spacer(20);
        }
    }

    &.swiper {
        overflow: visible;
    }
}

;@import "sass-embedded-legacy-load-done:101";