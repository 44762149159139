.menu--mobile {
    font-size: initial;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1.2em;

    .menu__item {
        font-size: px-to-rem(24);
        border: unset;
    }

    .menu-cta {
        font-size: initial;
    }

    .menu__link {
        padding: 0;
    }

    .menu__item--level-2 {
        font-size: px-to-rem(20);
        border: unset;
    }

    .menu__item--level-3 {
        font-size: px-to-rem(16);
        border: unset;
        padding-left: spacer(3);
    }

    .menu__item-counter {
        font-size: px-to-rem(16);
        opacity: 0.5;
    }

    .menu__button--back {
        opacity: 0.75;
        font-size: initial;
        padding: unset;
    }
}

;@import "sass-embedded-legacy-load-done:108";