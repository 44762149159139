$darken-amount: 10%;
$button-transition-duration: $global-duration * 1.2;

.btn {
    display: inline-flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    background: initial;
    line-height: 1.3;
    padding: spacer(4) spacer(8);
    color: initial;
    text-decoration: none;
    color: $white;
    width: auto;
    gap: spacer(4); // min amount of space between text and icon
    z-index: 1;
    font-size: px-to-rem(16);
    border: none;
    @extend .btn--rounded;

    .icon {
        transition: transform $global-duration;
    }

    &:hover {
        .icon {
            transform: translateX(#{spacer(1)});
        }

        &[target="_blank"] {
            .icon {
                transform: translateX(#{spacer(1)}) translateY(-#{spacer(1)});
            }
        }
    }
}

// Button colors
@each $name, $map in $btn-colors {
    .btn--#{$name} {
        background-color: map-get($map, "background");
        transition: background-color $button-transition-duration;
        color: map-get($map, "color");
        box-shadow: 0px 8px 20px -4px $quinary-100;
        --pe-popup-btn-background: #{map-get($map, "background")};

        &:hover {
            background-color: map-get($map, "hoverColor") !important;
        }
    }
}

.btn--ghost {
    border: 1px solid $white;
    color: $white;
    background: transparent;

    &:hover {
        background-color: $white;
        color: $color-base;
        transition: background-color $button-transition-duration;
    }
}

.btn-group {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: spacer(4);
    justify-content: flex-start;
}

// Used to show icon first, button text second
.btn--reversed {
    flex-direction: row-reverse;
}

.btn--rounded {
    border-radius: 50rem;
}

/* Add this class to any button with an icon inside of it
this will take care of the icon padding when used before or after text
*/
.btn__icon {
    display: flex;
    align-items: center;
    position: relative;

    .icon:not(:first-child) {
        padding-right: 0;
    }

    .icon:not(:last-child) {
        padding-left: 0;
    }
}

.btn--center {
    width: fit-content;
    margin: 0 auto;
}

.btn--video {
    position: relative;

    // &:after {
    //     content: "play_circle";
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     right: 0;
    //     text-align: center;
    //     font-family: "Material Icons";
    //     font-size: 4rem;
    //     top: 50%;
    //     transform: translateY(-50%);
    // }
}

.btn--disabled {
    opacity: 0.6;
    pointer-events: none;
    cursor: default;
}

.btn-return {
    padding: spacer(2) spacer(4);

    & > .icon {
        pointer-events: none;
    }
}

.archive-toggle {
    button {
        background: transparent;
        color: transparent;
        border: 0;
        background-size: contain;
        background-repeat: no-repeat;
        width: spacer(11);
        height: spacer(11);
        &.list {
            background-image: url("../../img/icons/list.svg");
            &.is-active {
                background-image: url("../../img/icons/list-active.svg");
            }
        }
        &.grid {
            background-image: url("../../img/icons/grid.svg");
            &.is-active {
                background-image: url("../../img/icons/grid-active.svg");
            }
        }
    }
}

.pe-popup-button-open {
    .btn {
        box-shadow: unset !important;
        text-align: center;
    }

    .icon {
        display: none !important;
    }
}

;@import "sass-embedded-legacy-load-done:75";