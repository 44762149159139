input[type="radio"] {
    @extend .form-check-input;

    background-size: auto;
    width: spacer(4) !important;
    height: spacer(4) !important;
    margin: 0;
    outline: unset;

    &:checked {
        --pe-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-3 -3 6 6'%3e%3ccircle r='2' fill='#{url-friendly-color($secondary)}'/%3e%3c/svg%3e");
        background-color: $white;

        box-shadow: unset;
        border-color: $secondary !important;
    }

    &:focus {
        box-shadow: unset;
        border-color: $secondary !important;
    }
}

;@import "sass-embedded-legacy-load-done:124";