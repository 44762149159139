.pagination-block {
    display: flex;
    justify-content: center;
    .pagination {
        display: flex;
        gap: spacer(4);
        align-items: center;
        padding-left: 0;
        text-decoration: none;
        list-style-type: none;
        color: rgba($quinary, .5);
        .prev, .next {
            align-items: center;
            display: flex;
        }
        a {
            color: rgba($quinary, .5);
            text-decoration: none;
            &:hover {
                color: $primary;
            }
        }
        .prev {
            margin-right: spacer(8);
        }
        .next {
            margin-left: spacer(8);
        }
        li {
            &:before {
                display: none;
            }
            margin: 0 spacer(2);

            &:first-of-type {
                margin-left: auto !important;
            }

            &:last-of-type {
                margin-right: auto !important;
            }            

            &.current {
                color: $quinary;
            }
            

            &.btn {
                color: $secondary;
                a {
                    color: $secondary;
                    text-decoration: none;
                }
                &.disabled {
                    opacity: .6;
                    &:before {
                        display: none;
                    }
                    &:hover {
                        transition: none; 
                    }
                }
            }
        }

        .first {
            display: flex;
            flex-direction: row-reverse;

            .material-icons {
                transform: scaleX(-1);
            }
        }
    }   
} 
;@import "sass-embedded-legacy-load-done:116";