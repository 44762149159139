// default icon style
.icon {
    display: inline-flex;
    height: 100%;

    svg {
        height: 100%;
    }
}

.icon--return {
    transform: scaleX(-1);
}

.icon--logo {
    display: flex;
    height: auto;
    svg {
        height: auto;
    }
    width: spacer(17);
    @include media-breakpoint-up(lg) {
        width: spacer(26);
    }
}
// loop trough thema colors
@each $name, $color in $theme-colors {
    .icon--#{$name} {
        color: $color;
    }
}

svg {
    fill: currentColor;
    width: 100%;
}

// Styling for svg sprite
#svg-sprite {
    visibility: hidden;
    max-height: 0;
    z-index: -100;
    overflow: hidden;
    position: absolute;
}
;@import "sass-embedded-legacy-load-done:77";