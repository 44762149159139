.scrolley {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: spacer(3);
}

[data-scrolley] {
    cursor: pointer;
}

.scrolley--absolute {
    position: absolute;
    bottom: 0;
    left: 3vw;
}

.scroll-icon {
    display: block;
    position: relative;
    height: px-to-rem(24);
    width: px-to-rem(14);
    border: px-to-rem(2) solid $white;
    border-radius: 1em;
}

.scroll-line {
    height: 32px;
    width: 2px;
    background-color: $white;
    display: block;
}

.scroll-icon__wheel-outer {
    display: block;
    position: absolute;
    left: 50%;
    top: 20%;
    height: 8px;
    width: 3px;
    border-radius: 5px;
    overflow: hidden;
    transform: translateX(-50%);
    left: 50%;
}

.scroll-icon__wheel-inner {
    display: block;
    height: 100%;
    width: 100%;
    border-radius: inherit;
    background: $white;
    animation: scrolley 2500ms infinite forwards;
}

@keyframes scrolley {
    0% {
        transform: translateY(0);
    }
    25% {
        transform: translateY(100%);
    }
    50% {
        transform: translateY(0);
    }
    75% {
        transform: translateY(-100%);
    }
    100% {
        transform: translateY(0);
    }
}

;@import "sass-embedded-legacy-load-done:129";