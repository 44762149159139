.facetwp-facet {
    display: flex;
    flex-direction: row;
    gap: spacer(5);
    margin-bottom: 0 !important;
    overflow-x: auto;

    .facetwp-radio {
        background-color: $white;
        font-size: px-to-rem(16);
        letter-spacing: 0.25px;
        padding: spacer(3) spacer(5);
        border: 1px solid $quinary;
        border-radius: #{$border-radius-lg * 4};
        font-weight: $font-weight-medium;
        color: $quinary;
        background-image: none;
        flex-shrink: 0;
        transition: background-color $global-duration;

        &.checked {
            background-image: none;
            color: $white;
            background-color: $quinary;
        }

        .facetwp-display-value {
            padding-right: 0 !important;
        }

        .facetwp-counter {
            display: none;
        }
        &:hover {
            background-color: rgba($quinary, .05);
        }
    }

    .facetwp-checkbox {
        margin: 0;
    }

    .facetwp-overflow {
        display: inherit;
        flex-direction: inherit;
        gap: inherit;

        &.facetwp-hidden {
            display: none;
        }
    }
}

.facetwp-toggle {
    font-size: px-to-rem(12);
    color: $color-base;
}

.filter {
    &--breakout {
        @include media-breakpoint-down(lg) {
            margin-right: calc((50% - 50vw) + #{spacer(2)});
        }
    }

    &--mobile {
        background-color: $secondary;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        padding: spacer(8) 0;
        z-index: $zindex-fixed;
        padding-top: spacer(24);
        padding-bottom: 0;
        gap: spacer(7);
        transform: translateX(-100%);
        transition: transform #{$global-duration * 2};

        &.active {
            transform: translateX(0%);
        }

        .btn--round {
            padding: 0;
            width: spacer(8);
            height: spacer(8);
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 100%;
        }

        .container--line {
            height: 100%;
        }

        .facetwp-facet {
            overflow: unset;
            flex-wrap: wrap;
            flex-direction: column;
        }

        &__facet {
            display: flex;
            flex-direction: column;
            gap: spacer(7);

            @include style-headings() {
                margin-bottom: spacer(3);
                font-weight: $font-weight-bold;
            }
        }
    }
}

.facetwp-type-checkboxes {
    .facet {
        display: flex;
        flex-wrap: wrap; 
        gap: spacer(4);
    }
    .facetwp-checkbox {
        // @extend .input-checkbox;
        font-size: px-to-rem(12);
        letter-spacing: 0.4px;
    }
}

.sort {
    background-color: rgba($white, .5);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: spacer(3) spacer(6);

    .facetwp-per-page-select {
        background-color: $white;
    }
}

;@import "sass-embedded-legacy-load-done:81";