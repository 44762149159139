.quicklink {
    display: flex;
    transition: box-shadow $global-duration;
    cursor: pointer;
    flex-wrap: wrap;

    &:hover {
        .quicklink__title {
            transform: translateX(spacer(2));
        }
        .quicklink__icon {
            transform: translateX(0.5rem);
        }
    }

    border-bottom: 1px solid $quinary-100;
}

.quicklink-divider {
    width: 1px;
    background: $quinary-100;
}

.quicklink--archive {
    margin: spacer(4);

    @include media-breakpoint-down(lg) {
        right: 0;
    }

    @include media-breakpoint-up(md) {
        width: calc(50% - #{spacer(4) * 2});
    }

    @include media-breakpoint-up(lg) {
        width: calc(25% - #{spacer(4) * 2});
    }
}

.quicklink--grey {
    background: $secondary;
}

.quicklink__icon {
    transition: transform $global-duration;
}

.quicklink_link {
    flex: 1;
}

.quicklink__title,
.quicklink__content {
    font-size: px-to-rem(20);
    font-family: $headings-font-family;
    color: $quinary;
    transition: transform $global-duration;

    @include media-breakpoint-up(lg) {
        font-size: px-to-rem(24);
    }
}

.quicklink__title {
    margin-bottom: 0;
}

.quicklink__content {
    margin-bottom: 0;
    font-size: px-to-rem(14);
}

.quicklink-container {
    position: relative;
    padding: spacer(5) 0;
    flex: 1;
    align-items: center;
    display: flex;
    flex-wrap: nowrap;

    .icon {
        flex-shrink: 0;
    }
}

.quicklink--vacancy {
    display: flex;
   
    .quicklink_link {
        display: flex;
        color: inherit;
        flex-direction: column;
        @include media-breakpoint-up(md) {
            flex-direction: row;
        }
    }
    .quicklink__title {
        @include media-breakpoint-up(md) {
            width: 50%;
        }
    }
    .quicklink__content {
        @include media-breakpoint-up(md) {
            width: 50%;
            margin-left: $grid-gutter-width;
            gap: spacer(10);
        }
        @include media-breakpoint-up(xl) {
            gap: spacer(20);
        }
        display: flex;
        align-items: center;
        gap: spacer(5);

        .quicklink__data-item {
            font-size: $font-size-base;
            display: flex;
            align-items: center;
            gap: spacer(2);
        }
        .quicklink__icon {
            margin-left: auto;
        }
    }
    &:hover {
        .quicklink__icon {
            transform: translateX(spacer(2)) translateY(-#{spacer(2)});
        }
    }
}
;@import "sass-embedded-legacy-load-done:96";