// Card component

.card {
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
    background: $white;
    box-shadow: $box-shadow;
    min-height: 100%;
    transition: all 350ms ease-in;
    width: 100%;
    border: 1px solid rgba($color-base, 0.1);
    border-radius: $border-radius;
    overflow: hidden;
    margin-top: 0px;
    padding: 0;
    min-width: unset;
    max-width: unset;

    &:hover {
        transform: translateY(-10px);
        box-shadow: $box-shadow-hover;
    }
}

.card__arrow {
    @extend .btn--rounded;
    color: $quinary;
    display: flex;
    padding: 1rem;
    background: $white;
    position: absolute;
    bottom: spacer(5);
    right: spacer(5);
}
.card__container {
    display: flex;
    flex-direction: column;
    background-color: $white;
    width: 100%;
}

.card__body,
.card__footer {
    padding: spacer(8);
}
.card__body {
    flex: 1;
}

.card__header {
    position: relative;
}

.card__title {
    font-size: $h3-font-size;
}

.card__image {
    height: 280px;
    width: 100%;
    object-fit: cover;
    max-width: unset;
}

.card__content {
    overflow-wrap: break-word;
    flex: 1;
}

.card__footer {
}

// Bade in cart
.card__badge {
    position: absolute;
    top: spacer(2);
    left: spacer(2);
    margin: 0;
    max-width: calc(100% - #{$spacer});
    overflow: hidden;
    text-overflow: ellipsis;
}

// Bordered card
.card--bordered {
    border: $card-border-width solid $card-border-color;

    .card-body,
    .card-footer {
        padding: $card-spacer-y;
    }
}

// Horizontal card
.card-horizontal-img {
    flex: 0 0 auto;
    width: auto;
}

// Responsive horizontal cards
@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        .card#{$infix}-horizontal {
            flex-direction: row;
        }
    }
}

;@import "sass-embedded-legacy-load-done:90";