html.noCursor {
    [data-curseur] {
        cursor: none !important;

        & > * {
            cursor: none !important;
        }

        a {
            cursor: none !important; // Remove default behavior in favor of curseur
        }
    }
}

.curseur {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    position: fixed;
    transform: translate(-50%, -50%);
    z-index: 90000;
    pointer-events: none;

    // Default cursor
    .defaultCurseur {
        background-color: var(--curseur-backgroundColor);
        width: var(--curseur-width);
        height: var(--curseur-height);

        &.is-hovered {
            --curseur-backgroundColor: #{rgba($secondary, 0.5)};
            transform: scale(3.5);
        }
        &.is-clicked {
            transform: scale(0.75);
        }
    }

    & > * {
        display: block;
        position: absolute;
        width: max-content;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        transform: scale(0);
        transition: all $global-duration;

        &.is-active {
            opacity: 1;
            transform: scale(1);
        }
    }
}

.followcursor {
    transform: translate(-50%, -50%);
}

;@import "sass-embedded-legacy-load-done:131";