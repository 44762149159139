@include color-mode(light) {
    &.main-header {
        color: $quinary;
    }

    &.hero {
        @extend %bg-light;
    }

    .ups__title {
        color: $quinary;
    }

    &.hero {
        @extend %bg-light;
    }

    &.bg--distortion {
        &:after {
            filter: opacity(7%);
        }
    }
}

;@import "sass-embedded-legacy-load-done:125";