@mixin container-fluid(
    $side,
    $specific-breakpoint,
    $active: active,
    $max-widths: $container-max-widths,
    $breakpoints: $grid-breakpoints
) {
    @if $active == none {
        @include media-breakpoint-up($specific-breakpoint) {
            @each $breakpoint, $container-max-width in $max-widths {
                @include media-breakpoint-up($breakpoint, $breakpoints) {
                    max-width: #{$container-max-width};
                    margin-right: auto;
                    margin-left: auto;
                }
            }
        }
    } @else {
        @if $specific-breakpoint == "none" {
            @each $breakpoint, $container-max-width in $max-widths {
                @include media-breakpoint-up($breakpoint, $breakpoints) {
                    max-width: calc(
                        #{$container-max-width} + (
                                (100% - #{$container-max-width}) / 2
                            ) - #{$container-padding-x}
                    );
                    @if $side == center {
                        margin-right: auto;
                        margin-left: auto;
                    } @else {
                        margin-#{$side}: 0;
                    }
                }
            }
        } @else {
            @include media-breakpoint-up($specific-breakpoint) {
                @each $breakpoint, $container-max-width in $max-widths {
                    @include media-breakpoint-up($breakpoint, $breakpoints) {
                        max-width: calc(
                            #{$container-max-width} + (
                                    (100% - #{$container-max-width}) / 2
                                ) - #{$container-padding-x}
                        );
                        @if $side == center {
                            margin-right: auto;
                            margin-left: auto;
                        } @else {
                            margin-#{$side}: 0;
                        }
                    }
                }
            }
        }
    }
}

@mixin carousel-destroy(
    $specific-breakpoint,
    $active: active,
    $max-widths: $container-max-widths,
    $breakpoints: $grid-breakpoints
) {
    @if $active == none {
        @include media-breakpoint-up($specific-breakpoint) {
            display: block;
            flex-wrap: nowrap;

            &:after {
                content: "flickity" !important;
                display: none !important;
            }

            .carousel__slide {
                flex: unset;
                min-width: unset;
                max-width: unset;
                margin: inherit;
                width: inherit;
            }

            .custom__carousel--navigation {
                display: flex !important;
            }
        }
    } @else {
        @if $specific-breakpoint == none {
            display: flex;
            flex-wrap: wrap;

            &:after {
                content: "" !important;
            }

            .carousel__slide {
                flex: 0 0 auto;
                min-width: 20%;
                max-width: 50%;
                margin-right: 0;
                width: auto;
            }

            .custom__carousel--navigation {
                display: none !important;
            }
        } @else {
            @include media-breakpoint-up($specific-breakpoint) {
                display: flex;
                flex-wrap: wrap;

                &:after {
                    content: "" !important;
                }

                .carousel__slide {
                    flex: 0 0 auto;
                    min-width: 20%;
                    max-width: 50%;
                    margin-right: 0;
                    width: auto;
                }

                .custom__carousel--navigation {
                    display: none !important;
                }
            }
        }
    }
}

@mixin style-headings($from: 1, $to: 6) {
    @for $i from $from through $to {
        h#{$i} {
            @content;
        }
    }
}

@mixin break-out(
    $side,
    $max-widths: $container-max-widths,
    $breakpoints: $grid-breakpoints
) {
    @each $breakpoint, $container-max-width in $max-widths {
        @include media-breakpoint-up($breakpoint, $breakpoints) {
            width: calc(100% + (
                (100dvw - #{$container-max-width}) / 2)
              ) !important;
    
            @if $side == left {
                margin-left: calc((100dvw - #{$container-max-width}) / -2);
            }
        }
    }
    
}
;@import "sass-embedded-legacy-load-done:4";