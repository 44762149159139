@include color-mode(dark) {
    &.main-header {
        color: $white;
        @include media-breakpoint-up(lg) {
            &--active {
                backdrop-filter: blur(#{spacer(5)});
                -webkit-backdrop-filter: blur(#{spacer(5)});
                background-color: rgba($quinary, 0.25);
            }
        }
        .main-header__container {
            @include media-breakpoint-up(lg) {
                border-bottom: 1px solid rgba($white, 0.15);
            }
        }
    }

    .editor {
        color: $quinary-200;
    }

    .list__title {
        color: $quinary-200;
    }

    .editor--hero {
        a {
            color: $white;
            text-decoration: $link-decoration;

            &:hover {
                color: $link-hover-color;
            }
        }
    }

    .ups__text {
        color: $quinary-200;
    }

    .btn--secondary {
        box-shadow: 0px 8px 20px -4px $quinary-400;
    }

    &.hero {
        @extend %bg-dark;
    }

    &.hero-text {
        @extend %bg-dark;
    }

    .breadcrumbs {
        .breadcrumbs__link {
            color: $quinary-200;
            &--reversed {
                color: $white;
            }
        }

        .breadcrumb_last {
            color: $white;
        }

        .breadcrumbs__seperator {
            color: $white;
        }
        .breadcrumbs__link--divider {
            @include media-breakpoint-up(lg) {
                border-right: 1px solid rgba($white, .5);
            }
        }
    }

    .label {
        color: $white;
        background: rgba($white, .07);
    }
}

;@import "sass-embedded-legacy-load-done:126";