.video__container {
    position: relative;
    
    .container {
        @include media-breakpoint-up(lg) {
            position: absolute;
            left: 0;
            right: 0;
            bottom: spacer(12);
        }
    }
    .btn--video {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateY(-50%) translateX(-50%);
        padding: spacer(4);

        @include media-breakpoint-up(lg) {
            position: relative;
            left: unset;
            top: unset;
            transform: unset;
            padding: spacer(4) spacer(8);
        }
    }
}
;@import "sass-embedded-legacy-load-done:83";