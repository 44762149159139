@keyframes blob-top-left {
    0%{
        transform: translate(-42%, -50%);
    }
    50%{
        transform: translate(-25%, -40%);
    }
    100%{
        transform: translate(-42%, -50%);
    }
}
@keyframes blob-bottom-left {
    0%{
        transform: translate(-20%, -60%);
    }
    50%{
        transform: translate(-10%, -70%);
    }
    100%{
        transform: translate(-20%, -60%);
    }
}
@keyframes blob-bottom-right {
    0%{
        transform: translate(45%, 36%);
    }
    50%{
        transform: translate(25%, 15%);
    }
    100%{
        transform: translate(45%, 36%);
    }
}

.blobs {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    pointer-events: none;
    z-index: -1;
}

.blob {
    position: absolute;
    width: 1048px;
    height: 1048px;
    pointer-events: none;
    background-image: radial-gradient(
        circle at 50% 50%,
        rgba(255, 222, 20, 0.28) 0%,
        rgba(255, 222, 20, 0) 62%,
        rgba(255, 222, 20, 0) 62%
    );

    &--primary {
        background-image: radial-gradient(
            circle at 50% 50%,
            rgba(255, 222, 20, 0.28) 0%,
            rgba(255, 222, 20, 0) 62%,
            rgba(255, 222, 20, 0) 62%
        );
        opacity: 0.32;
    }

    &--secondary {
        background-image: radial-gradient(
            circle at 31% 61%,
            rgba(242, 139, 76, 0.63) 0%,
            rgba(242, 139, 76, 0) 47%
        );
        opacity: 0.31;
    }

    &--tertiary {
        background-image: radial-gradient(
            circle at 50% 50%,
            rgba(81, 196, 255, 0.32) 0%,
            rgba(81, 196, 255, 0) 50%
        );
        opacity: 0.64;
    }

    &--top-left {
        top: 0;
        left: 0;
        transform: translate(-42%, -50%);
        animation: blob-top-left 12s linear alternate infinite;
    }

    &--bottom-left {
        top: 100%;
        left: 0;
        transform: translate(-20%, -60%);
        animation: blob-bottom-left 12s linear alternate infinite;
        animation-delay: 1.5s;
    }

    &--bottom-right {
        bottom: 0;
        right: 0;
        transform: translate(45%, 36%);
        animation: blob-bottom-right 16s linear alternate infinite;
        animation-delay: 3s;
    }
}

;@import "sass-embedded-legacy-load-done:87";