.card--information {
    box-shadow: unset;
    border: 0;
    border-radius: 0;
    gap: spacer(5);
    overflow: visible;
    .card__image {
        width: auto;
        max-width: spacer(12);
        max-height: spacer(12);
        @extend .object-fit--contain;
    }
    .card__body,
    .card__footer {
        padding: 0;
    }
    .card__body {
        color: rgba($quinary, 0.75);
    }
    &:hover {
        transform: unset;
        box-shadow: unset;
    }
}
;@import "sass-embedded-legacy-load-done:94";