%gchoice-focus {
    border-color: $secondary !important;
}

input[type="checkbox"] {
    @extend .form-check-input;

    width: spacer(4) !important;
    height: spacer(4) !important;
    margin: 0;

    &:checked {
        background-color: $secondary !important;
        border-color: $secondary;
    }

    &:focus {
        box-shadow: unset !important;
        border-color: inherit;
    }
}

.gchoice {
    display: flex;
    align-items: center;
    gap: spacer(3);
}


.gfield-choice-input {
    &:focus {
        @extend %gchoice-focus;
    }

    &:checked {
        &:focus {
            @extend %gchoice-focus;
        }
    }
}

;@import "sass-embedded-legacy-load-done:123";