.pane {
    display: block;

    @each $breakpoint, $pane-spacer in $pane-spacers {
        @include media-breakpoint-up($breakpoint) {
            padding: $pane-spacer 0;
        }
    }

    &.bg--default {
        & + .pane.bg--default {
            padding-top: 0;
        }
    }
}

@each $name, $color in $theme-colors {
    .pane.bg--#{$name} {
        + .pane.bg--#{$name} {
            padding-top: 0;
        }
    }
}
.pane--text_expertises {
    .expertise-title {
        font-size: px-to-rem(20);
        @extend .subheading;
    }
}
.pane--swiper_cases {
    overflow: hidden;
    .swiper--cases {
        overflow: visible;
    }
}
.pane--visual-fullscreen {
    padding-top: 0;
    padding-bottom: 0;
    overflow: hidden;
    max-height: 75vh;
    display: flex;
    align-items: center;

    .img--visual {
        max-height: unset;
        height: 80vh;
    }

    &.bg--default {
        &:has(+ .bg--primary) {
            background: linear-gradient(
                to bottom,
                $white 0%,
                $white 50%,
                $bg-primary 50%,
                $bg-primary 100%
            );
        }
        &:has(+ .bg--secondary) {
            background: linear-gradient(
                to bottom,
                $white 0%,
                $white 50%,
                $bg-secondary 50%,
                $bg-secondary 100%
            );
        }
    }
    &.bg--primary {
        &:has(+ .bg--default) {
            background: linear-gradient(
                to bottom,
                $bg-primary 0%,
                $bg-primary 50%,
                $white 50%,
                $white 100%
            );
        }
        &:has(+ .bg--secondary) {
            background: linear-gradient(
                to bottom,
                $bg-primary 0%,
                $bg-primary 50%,
                $bg-secondary 50%,
                $bg-secondary 100%
            );
        }
    }
    &.bg--secondary {
        &:has(+ .bg--default) {
            background: linear-gradient(
                to bottom,
                $bg-secondary 0%,
                $bg-secondary 50%,
                $white 50%,
                $white 100%
            );
        }
        &:has(+ .bg--primary) {
            background: linear-gradient(
                to bottom,
                $bg-secondary 0%,
                $bg-secondary 50%,
                $bg-primary 50%,
                $bg-primary 100%
            );
        }
    }

    & + .pane {
        @each $breakpoint, $pane-spacer in $pane-spacers {
            @include media-breakpoint-up($breakpoint) {
                padding-top: $pane-spacer !important;
            }
        }
    }
}

.pane--pull-top {
    padding-top: 0;
    @each $breakpoint, $pane-spacer in $pane-spacers {
        @include media-breakpoint-up($breakpoint) {
            margin-top: -#{$pane-spacer};
        }
    }
}

.hero,
.pane {
    &:has(+ .pane--pull-top) {
        @each $breakpoint, $pane-spacer in $pane-spacers {
            @include media-breakpoint-up($breakpoint) {
                padding: $pane-spacer 0 calc(#{$pane-spacer} * 2) 0;
            }
        }
    }
}

.pane--two_column_text_image {
    overflow: hidden;
}

.pane--request_form {
    @each $breakpoint, $pane-spacer in $pane-spacers {
        @include media-breakpoint-up($breakpoint) {
            padding: $pane-spacer 0;
            padding-bottom: calc(#{$pane-spacer} * 1.25);
        }
    }
}

;@import "sass-embedded-legacy-load-done:73";