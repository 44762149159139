%tab-item-border {
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    left: 0;
    bottom: 0;
    background-color: #d8d8d8;
}

%subheading {
    margin-top: 0; // 1
    margin-bottom: $subheadings-margin-bottom;
    font-family: $subheadings-font-family;
    line-height: $subheadings-line-height;
    color: var(--#{$prefix}subheading-color);
}

%distortion {
    background-image: url("../../img/backgrounds/distortion.svg");
    filter: opacity(3%);
}

%divider {
    width: 32px;
    height: 3px;
    background-color: $primary;
    position: relative;
}

%bg-dark {
    background-color: $quinary;
}

%bg-light {
    background-color: $white;
}

%is-hidden {
    transition: 300ms opacity;
    opacity: 0;
    pointer-events: none;
}

;@import "sass-embedded-legacy-load-done:34";