.card--case {
    box-shadow: unset;
    border: 0;
    border-radius: 0;
    hr {
        margin: spacer(5) 0;
    }
    .card__image {
        transition: transform $global-duration;
    }
    .card__body,
    .card__footer {
        padding: 0;
    }
    .card__title {
        font-size: $h5-font-size;
    }
    .card__subtitle {
        font-size: px-to-rem(12);
        color: rgba($quinary, 0.5);
        letter-spacing: 0.75px;
        @extend %subheading;
    }
    .card__header {
        aspect-ratio: 4/5;
        height: auto;
        width: calc(100% + #{$grid-gutter-width});
        position: relative;
        overflow: hidden;
        img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        .card__arrow {
            right: spacer(15);
            transition: transform $global-duration;
        }
    }
    &:hover {
        transform: unset;
        box-shadow: unset;
        .card__image {
            transform: scale(1.1);
        }
        .card__arrow {
            transform: rotate(45deg);
        }
    }
    &.card--big {
        .card__header {
            aspect-ratio: 8/5;
            height: auto;
            .card__arrow {
                right: spacer(15);
            }
        }
        &:hover {
            .card__image {
                transform: scale(1.05);
            }
        }
    }
}

.card--case-slim {
    background: unset;
    border: unset;
    box-shadow: unset;
    overflow: unset;
    transition: clip-path $global-duration, transform 150ms, opacity 150ms;
    gap: spacer(4);

    .card__body,
    .card__footer {
        padding: unset;
    }

    .card__header {
        position: unset;
    }

    .card__title {
        font-size: px-to-rem(22);
    }

    .card__subtitle {
        font-size: px-to-rem(14);
    }

    .card__image,
    .card__image img {
        position: absolute;
        left: 0;
        bottom: 60%;
        height: auto;
        width: 100%;
        object-fit: cover;
        opacity: 0;
        transition: clip-path $global-duration, transform 150ms, opacity 150ms,
            clip-path 350ms;
        pointer-events: none;
        clip-path: polygon(5% 5%, 95% 5%, 95% 95%, 5% 95%);
        will-change: transform;
        aspect-ratio: 16/9;

        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    .btn {
        position: unset;
    }

    &:hover {
        transform: unset;
        transition: unset;
        box-shadow: unset;

        .card__image,
        .card__image img {
            opacity: 1;
            clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
        }
    }
}

;@import "sass-embedded-legacy-load-done:93";