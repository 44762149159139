.menu--primary {
    justify-content: center;
    padding: spacer(8);
    transition: all 300ms;

    @include media-breakpoint-up(lg) {
        padding: unset;
    }

    &--hiddden {
        opacity: 0;
        pointer-events: none;
    }

    // Menu item level 1
    & > .menu__item {
        display: flex;
        align-items: stretch;
        transition: background-color $global-duration ease-in;

        &:hover {
            @include media-breakpoint-up(lg) {
                // background-color: $primary;
            }
        }
        &.is-active {
            .menu__link {
                opacity: 1;
                &:hover {
                    opacity: 0.5;
                }
            }
        }
    }

    .menu__link {
        display: flex;
        align-items: center;
        height: 100%;
        opacity: 0.5;
        transition: opacity $global-duration;

        &:hover {
            opacity: 1;
        }

        @include media-breakpoint-up(lg) {
            padding-top: 0;
            padding-bottom: 0;
        }
    }
}

;@import "sass-embedded-legacy-load-done:106";