h1,
h2,
h3,
h4,
h5,
h6 {
    padding: 0;
}

.subheading,
.subtitle {
    @extend %subheading;
}

;@import "sass-embedded-legacy-load-done:71";