$hero-spacer-top: px-to-rem(200);
$hero-spacer-bottom: px-to-rem(90);

.hero-text {
    @each $breakpoint, $pane-spacer in $pane-spacers {
        @include media-breakpoint-up($breakpoint) {
            padding-top: calc((#{$pane-spacer} * 2) + #{$header-height});
            padding-bottom: #{$pane-spacer};
        }
    }

    &:has(+.pane--pull-top) {
        @each $breakpoint, $pane-spacer in $pane-spacers {
            @include media-breakpoint-up($breakpoint) {
                padding-top: calc((#{$pane-spacer} * 2) + #{$header-height});
                padding-bottom:  calc((#{$pane-spacer} * 2));
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:113";